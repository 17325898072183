<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ paymentTypePeriod.periodoPagoTipoId == 0 ? $t('Routes.PaymentTypePeriodAdd'): $t('Routes.PaymentTypePeriodEdit') }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col
            cols="12"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.paymentTypePeriod.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.paymentTypePeriod.columns.name')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="paymentTypePeriod.nombre"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col
            cols="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.paymentTypePeriod.columns.description')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-textarea
                  v-model="paymentTypePeriod.descripcion"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
            <b-spinner v-if="addingPaymentTypePeriod" small class="mr-1" />
            {{ paymentTypePeriod.periodoPagoTipoId == 0 ? $t('Lists.Add'): $t('Lists.Edit') }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const addingPaymentTypePeriod = ref(false)
    const paymentTypePeriod = ref({
      periodoPagoTipoId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    })

    const blankPaymentTypePeriod = {
      periodoPagoTipoId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    }

    const {
      fetchPaymentTypePeriod,
      createPaymentTypePeriod,
      updatePaymentTypePeriod,
    } = humanResources()

    const resetPaymentTypePeriod = () => {
      paymentTypePeriod.value = JSON.parse(JSON.stringify(blankPaymentTypePeriod))
    }

    if (router.currentRoute.params.periodoPagoTipoId) {
      fetchPaymentTypePeriod(router.currentRoute.params.periodoPagoTipoId, data => {
        paymentTypePeriod.value = data
      })
    } else {
      paymentTypePeriod.value = JSON.parse(JSON.stringify(blankPaymentTypePeriod))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPaymentTypePeriod)

    const onSubmit = () => {
      addingPaymentTypePeriod.value = true
      if (paymentTypePeriod.value.periodoPagoTipoId === 0) {
        createPaymentTypePeriod(paymentTypePeriod.value, () => {
          addingPaymentTypePeriod.value = false
          router.push({ name: 'apps-human-resources-payment-type-period-list' })
        })
      } else {
        updatePaymentTypePeriod(paymentTypePeriod.value.periodoPagoTipoId, paymentTypePeriod.value, () => {
          addingPaymentTypePeriod.value = false
          router.push({ name: 'apps-human-resources-payment-type-period-list' })
        })
      }
    }

    return {
      paymentTypePeriod,
      addingPaymentTypePeriod,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,

    }
  },
}
</script>
